html, body, .container-fluid {
    height: 100%;
}

.row.main {
    height: 80%
}

body {
    font-family: Aeonik, Arial, Sans-Serif !important;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    background-color: #1E0028;
    margin: 0px;
}

div {
    color: #BEB4B4;
    text-align:left;
}

.logo {
    width: 100px;
    margin-left: 45px;
    margin-top: 45px;
}

@media screen and (max-width: 575px) {
    .logo {
        width: 100px;
        margin-left: 1em;
        margin-top: 1em;
    }
}

.instructions {
    color: #BEB4B4;
    line-height: 24px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0px;
}

.row.input-row {
    margin-bottom: 0px;
    margin-top: 30px;
}

.login-links {
    margin-bottom: 30px;
    margin-top: 14px;
}

.no-margin-bottom {
    margin-bottom: 0px;
}

img {
    max-width: 100%;
}

.form-control {
    height: 54px !Important;
    padding: 0px;
}

.form-control:focus {
    border: none;
    border-bottom: 1px solid #4A3152;
    background: #1E0028;
    box-shadow: none;
}


.form-area {
    margin: 0 auto;
    margin-top: 1rem;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    padding: 40px;
    overflow: auto;
    max-width: 480px;
    position: relative;
    min-height: 400px;
}

h2 {
    font-size: 40px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 25px;
}


input:not([type=checkbox]) {
    font-size: 20px;
    line-height: 24px;
    background: #1E0028;
    border: none;
    border-bottom: 1px solid #4A3152;
}

input[type=text], input[type=text]:focus, input[type=password], input[type=password]:focus {
    color: #ffffff;
}

input[type=submit] {
    font-size: 18px;
    height: 56px;
    margin-bottom: 0px;
    color: #1E0028;
}

input::-webkit-input-placeholder { /* Edge */
    color: #BEB4B4 !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #BEB4B4 !important;
}

input::placeholder {
    color: #BEB4B4 !important;
}

.list-group-item {
    background-color: inherit;
    border-color: #fff;
}

.list-group-item a {
    color: #1E0028;
    width: 100%;
}

.list-group-item a:hover {
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn {
    text-transform: none;
    color: #1E0028;
    border-radius: 0px;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 99%,rgba(0,0,0,1)100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 99%,rgba(0,0,0,1)100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 99%,rgba(0,0,0,1)100%);
    text-align: center;
    font-size: 18px;
    text-transform: none;
    background-color: #F9F8F8 !important;
    padding: 15px 27px;
    line-height: 28px;
    margin-bottom: 5px;
    margin-top: 40px;
    
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
	color: #1E0028;
}

.btn-primary:disabled {
    opacity: 0.2;
    color: #1E0028;
}

form {
    width: 100%;
}

.btn-outline-secondary {
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
}

form {
    width: 100%;
}


div.error-message {
    color: #EB5757;
    margin-bottom: 15px;
}

div.error-message.aad {
    margin-bottom: 0px;
    margin-top: 15px;
}

div .error-message a {
    color: #EB5757;
    text-decoration: underline;
}

div .error-message a:hover {
    text-decoration: none;
}

li.error-message {
    color: #ff6666;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
}

div.logout-message, div.success-message {
    color: #28a728;
    margin-top: 15px;
}

p.logout {
    margin-top: 20px;
}

p.forgot-password {
    margin-bottom: 0px;
}

a {
    color: #BEB4B4;
    text-decoration: underline;
}

 a:hover {
    color: #BEB4B4;
    text-decoration: none;
}

.top-border-block {
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: left;
    font-size: 18px;
    margin: 0px;
}

#status-container {
    margin: 20px auto;
}

#reset-password-failed h2 {
    font-size: 38px;
}

@media screen and (max-width: 575px) {
    .text-right {
        text-align: left !important;
    }
}

/*LOGIN SPINNER*/
.spinner {
    margin: -5px auto;
    margin-top: -20px;
    height: 25px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
    background-color: #ccc;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4)
    }

    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

.product-picker {
	display: block;
    text-align: center;
    font-size: 18px;
    color: #F9F8F8 !important;
    padding: 10px 20px;
}